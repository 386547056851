import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#FAFAFA',
      neutralLight3: '#E3DED9',
      neutralLight2: '#C4B39D',
      neutralLight1: '#B5A38D',
      neutralDark4: '#3B352F',
      neutralDark3: '#5C5F60',
      neutralDark2: '#36312B',
      neutralDark1: '#000000',
      primaryDark: '#B5A38D',
      primaryLight: '#BACBC9',
      secondaryDark: '#DE7556',
      secondaryLight: '#EF8565',
      danger: '#9A3E53',
    },
  },
  fontFamily: {
    heading: "'Prata', serif",
    paragraph: "'Inter', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
